import React from 'react';

const Input = (props) => {
    return (
        <div className="inputBox">
            <input type={props.type} value={props.value} onChange={props.onChange} required readOnly={props.readOnly} />
            <label>{props.label}</label>
            <span></span>
        </div>
    )
}

export default Input