import React, {useEffect, useState} from 'react';
import Input from "../../../atoms/Input";
import {updateMember} from "../../../apis/MemberController";

const UserCreateModal = ({ user, onClose, onSave }) => {
    const [id, setId] = useState(user.loginId);
    const [username, setUsername] = useState(user.username);
    const [password, setPassword] = useState('');
    const [checkPassword, setCheckPassword] = useState('');
    const [role, setRole] = useState( user.roles[0] === 'ROLE_ADMIN' ? 'ROLE_ADMIN' : 'ROLE_USER');

    const roleOptions = [
        { value: 'ROLE_ADMIN', label: '관리자' },
        { value: 'ROLE_USER', label: '일반 사용자' }
    ];

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (password !== checkPassword) {
            alert("비밀번호가 일치하지 않습니다.");
            return;
        }

        const data = {
            loginId: user.loginId,
            username: username === '' ? null : username,
            password: password === '' ? null : username,
            roles: [role],
            del_yn: "N"
        }

        try {
            const res = await updateMember(data);

            if(res.status === 200) {
                onSave();
                onClose();
            }
        } catch (error) {
            console.error('Failed to edit user:', error);
        }
    }

    useEffect(() => {
        setId(user.loginId);
        setUsername(user.username);
        setPassword('');
        setCheckPassword('');
        setRole(user.roles[0] === 'ROLE_ADMIN' ? 'ROLE_ADMIN' : 'ROLE_USER')
    }, [user]);

    return (
        <div className="userCreateModal">
            <div className="login_div">사용자 정보 수정
                <div className="inputBox">
                    <Input label={"아이디"} type={"read"} required={true} value={id}
                           onChange={(e) => setId(e.target.value)} readOnly={true}/>
                    <Input label={"사용자 이름"} type={"text"} required={true} value={username}
                           onChange={(e) => setUsername(e.target.value)}/>
                    <Input label={"패스워드"} type={"password"} required={true} value={password}
                           onChange={(e) => setPassword(e.target.value)}/>
                    <Input label={"패스워드 확인"} type={"password"} required={true} value={checkPassword}
                           onChange={(e) => setCheckPassword(e.target.value)}/>
                </div>

                <div className="role-selection">
                    <div className="role-title">권한</div>
                    <div className="role-options">
                        {roleOptions.map((option) => (
                            <div key={option.value} className="role-option">
                                <input
                                    type="radio"
                                    id={option.value}
                                    name="role"
                                    value={option.value}
                                    checked={role === option.value}
                                    onChange={(e) => setRole(e.target.value)}
                                />
                                <label htmlFor={option.value}>{option.label}</label>
                            </div>
                        ))}
                    </div>
                </div>

                <button onClick={(e) => handleSubmit(e)}>저장</button>
                <button onClick={() => onClose()}>취소</button>
            </div>
        </div>
    )
}

export default UserCreateModal;