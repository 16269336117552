import axios from 'axios';
import store from "../../modules/store";

export const updateMember = async (data) => {
    const state = store.getState();
    const userInfo = state.userInfo;

    try {
        return await axios.post(`http://192.168.219.74:8080/api/member/updateMember`, data, {
            headers: {
                'Authorization': `Bearer ${userInfo.uuid}`,
                'Accept': '*/*'
            }
        });
    } catch (error) {
        console.error('Failed to update member:', error);
        throw error;
    }
};
