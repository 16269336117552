import React, {useEffect, useRef, useState} from "react";
import './_style.scss';
import axios from "axios";

const Chat = () => {
    const [conversationList, setConversationList] = useState({});
    const [selectedConversation, setSelectedConversation] = useState();
    const [messageList, setMessageList] = useState([]);
    const [page, setPage] = useState(0);
    const [size, setSize] = useState(10);
    const [userMessage, setUserMessage] = useState("");
    const [token, setToken] = useState("");
    const messageEndRef = useRef(null);

    const getDateLabel = (conversationDate) => {
        const now = new Date();
        const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
        const yesterday = new Date(today);
        yesterday.setDate(today.getDate() - 1);

        const firstDayOfThisMonth = new Date(now.getFullYear(), now.getMonth(), 1);
        const firstDayOfLastMonth = new Date(now.getFullYear(), now.getMonth() - 1, 1);

        // "오늘" 먼저 확인
        if (conversationDate >= today) {
            return "오늘";
        }
        // 그 다음 "어제" 확인
        if (conversationDate >= yesterday && conversationDate < today) {
            return "어제";
        }
        // "이번 달"
        if (conversationDate >= firstDayOfThisMonth) {
            return "이번 달";
        }
        // "지난 달"
        if (conversationDate >= firstDayOfLastMonth && conversationDate < firstDayOfThisMonth) {
            return "지난 달";
        }
        // "작년"인 경우
        if (conversationDate.getFullYear() < now.getFullYear()) {
            return `작년 ${conversationDate.getMonth() + 1}월`;
        }
        // 몇 개월 전인 경우
        const monthsAgo = now.getMonth() - conversationDate.getMonth() + 12 * (now.getFullYear() - conversationDate.getFullYear());
        return `${monthsAgo}개월 전`;
    };

    const categorizeConversations = (conversations) => {
        const categorizedConversations = {};

        conversations.forEach(conversation => {
            const conversationDate = new Date(conversation.createDate);
            const label = getDateLabel(conversationDate);

            // Initialize the category if it doesn't exist
            if (!categorizedConversations[label]) {
                categorizedConversations[label] = [];
            }

            // Push the conversation into the correct date category
            categorizedConversations[label].push(conversation);
        });
        const sortedCategories = Object.keys(categorizedConversations).sort((a, b) => {
            if (a === "오늘") return -1;
            if (b === "오늘") return 1;
            if (a === "어제") return -1;
            if (b === "어제") return 1;
            return 0; // 기본적으로 다른 날짜는 순서대로 표시
        });
        const sortedConversations = {};
        sortedCategories.forEach(key => {
            sortedConversations[key] = categorizedConversations[key];
        });
        setConversationList(sortedConversations);
    };


    const getConversationList = async () => {
        try {
            const response = await axios.get("http://192.168.219.74:8080/api/chat/list", {
                params: {
                    page: page,
                    size: 100
                }
            });
            if (response.data.msg === 'success'){
                categorizeConversations(response.data.data.itemList);
            }
        }catch (error) {
            console.error('Error : ' + error);
        }
    }

    const getChatMessages = async (conversationId) => {

        try {
            const response = await axios.get("http://192.168.219.74:8080/api/chat/"+ conversationId + "/list",{
                params: {
                    page: page,
                    size: 100000
                }
            });
            if (response.data.msg === 'success'){
                setMessageList(response.data.data.itemList);
            }
        } catch (error) {
            console.error('Error : ' + error);
        }
    };

    const sendMessage = async () => {
        if (userMessage.trim() === "") return;

        const newMessageList = [...messageList, {sender: 'user', content: userMessage}];
        setMessageList(newMessageList);

        try {
            const response = await axios.post("http://192.168.219.74:8080/api/chat/ai/generate",{
                userMessage: userMessage,
                token: token
            });

            const aiMessage = response.data.message;
            setMessageList([...newMessageList, {sender: 'assistant', content: aiMessage}]);
            if (token.trim() === ""){
                setToken(response.data.token);
                setTimeout(() => {
                    getConversationList(); // 대화 목록을 갱신하여 새 대화 반영
                }, 2000);
            }
        } catch (error){
            console.error('Error', error);
        }
        setUserMessage("");
    }

    const handleConversationClick = (conversation) => {
        setSelectedConversation(conversation);
        setToken(conversation.conversationId);
        getChatMessages(conversation.conversationId);

    };

    const startNewConversation = () => {
        setMessageList([]); // 기존 메시지 비우기
        setSelectedConversation(null); // 선택된 대화 초기화
        setUserMessage(""); // 사용자 입력란 비우기
        setToken("");
    };

    useEffect(() => {
        if (messageList.length > 0) {
            messageEndRef.current?.scrollIntoView({ behavior: "smooth" });
        }
    }, [messageList]);

    useEffect(() => {
        getConversationList();
    }, [page, size]);

    return (
        <div className="chat-container">
            <div className="chat-list">
                {/* 새로운 대화 시작 버튼 */}
                <button onClick={startNewConversation}>새로운 대화 시작</button>
                {/* 채팅 목록 */}
                <ul>
                    {Object.keys(conversationList).map(label => (
                        <div key={label}>
                            <h3>{label}</h3>
                            {conversationList[label].map((conversation) => (
                                <li
                                    key={conversation.conversationId}
                                    onClick={() => handleConversationClick(conversation)}
                                    className={selectedConversation?.conversationId === conversation.conversationId ? 'selected' : ''}
                                >
                                    {conversation.title || "Untitled"}
                                </li>
                            ))}
                        </div>
                    ))}
                </ul>
            </div>
            <div className="chat-content">
                {/* 채팅 내용 */}
                <div className="messages">
                    {messageList.map((message, index) => (
                        <div key={index}
                        className={`message ${message.sender === 'user' ? 'user-message' : 'ai-message'}`}>
                            {message.content}
                        </div>
                    ))}

                </div>
                {/* 채팅 입력란 */}
                <div className="chat-input">
                    <input type="text"
                           value={userMessage}
                           onChange={(e) => setUserMessage(e.target.value)}
                           placeholder="Type a message..." />
                    <button onClick={sendMessage}>Send</button>
                </div>
            </div>
        </div>
    )
}

export default Chat;
