import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {isActiveModal, setModal} from "../../../modules/actions/modalAction";
import UserCreateModal from './userCreateModal/index'
import axios from "axios";
import {updateMember} from "../../apis/MemberController";

const UserList = () => {
    const userInfo = useSelector(status => status.userInfo);
    const dispatch = useDispatch();

    const [users, setUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    const openModal = (user) => {
        dispatch(setModal(<UserCreateModal user={user} onClose={() => dispatch(isActiveModal(false))} onSave={fetchUsers} />));
        dispatch(isActiveModal());
    }

    const getUserList = useCallback(async () => {
        const url = 'http://192.168.219.74:8080/api/member';
        const accessToken = userInfo.uuid;

        try {
            const response = await axios.get(url, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Accept': '*/*',
                }
            });
            return response.data;
        } catch (error) {
            console.error('Error:', error);
            throw error;
        }
    }, [userInfo.uuid]);

    const fetchUsers = useCallback(async () => {
        setIsLoading(true);
        setError(null);

        try {
            const data = await getUserList();
            console.log(data);
            setUsers(data);
        } catch (error) {
            console.error('Failed to fetch users:', error);
            setError('Failed to fetch users. Please try again later.');
        } finally {
            setIsLoading(false);
        }
    }, [getUserList]);

    useEffect(() => {
        fetchUsers();
    }, [fetchUsers]);

    const handleDelete = async (user) => {
        try {
            const data = {
                loginId: user.loginId,
                username: null,
                password: null,
                roles: null,
                del_yn: "Y"
            }

            const res = await updateMember(data);

            if(res.status === 200) {
                console.log(`Deleted user with login ID: ${user.loginId}`);
                await fetchUsers();
            }
        } catch (error) {
            console.error('Failed to edit user:', error);
            setError('Failed to edit user. Please try again.');
        }
    };

    return (
        <div className="userList">
            <div className="user-list">
                <h1>사용자 목록</h1>
                {isLoading ? (
                    <div>Loading...</div>
                ) : error ? (
                    <div>Error: {error}</div>
                ) : (
                    <table>
                        <thead>
                        <tr>
                            <th>로그인 ID</th>
                            <th>사용자 이름</th>
                            <th>권한</th>
                            <th>작업</th>
                        </tr>
                        </thead>
                        <tbody>
                        {users.map((user, index) => (
                            <tr key={index}>
                                <td>{user.loginId}</td>
                                <td>{user.username}</td>
                                <td>{user.roles.join(', ')}</td>
                                <td>
                                    <button onClick={() => openModal(user)}>수정</button>
                                    <button onClick={() => handleDelete(user)}>삭제</button>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                )}
            </div>
        </div>
    )
}

export default UserList;