import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {isActiveModal} from "../../../modules/actions/modalAction";

const Modal = () => {
    const activeModal = useSelector(status => status.isActiveModal);
    const modalComponent = useSelector(status => status.modalComponent);
    const dispatch = useDispatch();

    return (
        <div style={{display: activeModal ? "" : "none"}} className="modal">
            <div className="modalHeader" style={{opacity: activeModal ? "1" : "0"}}>
                <div className="closeBtn" onClick={() => dispatch(isActiveModal())}>
                    <i className="ri-close-line" />
                </div>
            </div>
            <div className="modalBody">
                {modalComponent}
            </div>
        </div>
    )
}

export default Modal;