const initialState = {
    //로그인 성공 실패 여부
    isAuthenticated: false,
    // 사이드 메뉴 접히는 부분
    isActiveSideMenu: true,
    // 모달 활성화
    isActiveModal: false,
    // 모달 Component 구성
    modalComponent: <></>,
    // 로그인 유저정보
    userInfo: {
        name: undefined,
        id: undefined,
        uuid: undefined
    },
};

const counterReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'isAuthenticated':
            return {...state, isAuthenticated: !state.isAuthenticated};
        case 'isActiveSideMenu':
            return {...state, isActiveSideMenu: !state.isActiveSideMenu};
        case 'isActiveModal':
            return {...state, isActiveModal: !state.isActiveModal};
        case 'setModal':
            return {...state, modalComponent: action.payload};
        case 'setUserInfo':
            return {...state, userInfo: action.payload};
        default:
            return state;
    }
};

export default counterReducer;