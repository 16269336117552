import React, { useState, useEffect } from 'react';
import {useDispatch, useSelector} from "react-redux";
import { isActiveModal, setModal } from "../../../modules/actions/modalAction";
import FileUploadModal from './FileUploadModal/index.js';
import axios from 'axios';

const FileList = () => {

    const dispatch = useDispatch();
    const [page, setPage] = useState(1);
    const [fileList, setFileList] = useState([]);

    const formatFileSize = (bytes) => {
        if (bytes === 0) return "0 Bytes";
        const k = 1024;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
    };

    const downloadFile = (fileName) => {
        const downloadUrl = `http://192.168.219.74:8080/api/file/download/${fileName}`;
        window.open(downloadUrl, '_blank');
    }

    const getFileList = async () => {
        try {
            const response = await axios.get('http://192.168.219.74:8080/api/file/list',{
                params:{
                    page: page - 1,
                    size: 10
                }
            });
            if (response.data.msg === "success"){
                setFileList(response.data.data.itemList);
            }
        } catch (error) {
            console.error('Error fetching file list:', error);
        }
    };

    const deleteFile = async (fileName) => {
        try {

            const response = await axios.delete(`http://192.168.219.74:8080/api/file/${fileName}`);

            getFileList(); // 파일 삭제 후 목록 갱신
        } catch (error) {
            console.error('Error in delete:', error);
        }
    };

    useEffect(() => {
        getFileList();
    }, []);


    const openFileUploadModal = () => {
        dispatch(setModal(
            <FileUploadModal />));
        dispatch(isActiveModal());

    }

    const styles = {
        fileList: {
            padding: '20px',
            width: '100%'
        },
        button: {
            margin: '0px 5px 0px 0px',
            padding: '10px 20px',
            backgroundColor: 'blue',
            color: 'white',
            border: 'none',
            cursor: 'pointer',
        },
        fileTable: {
            marginTop: '10px',
            with: '100%'
        },
        table: {
            width: '100%',
            borderCollapse: 'collapse',
        },
        th: {
            border: '1px solid #ddd',
            padding: '8px',
            textAlign: 'center',
            backgroundColor: '#f2f2f2',
        },
        td: {
            border: '1px solid #ddd',
            padding: '8px',
            textAlign: 'center',
        },
        evenRow: {
            backgroundColor: '#f9f9f9',
        },
    };

    return (
        <div style={styles.fileList}>
            <h2>FileList</h2>
            <button style={styles.button} onClick={openFileUploadModal}>파일 업로드</button>

            <div style={styles.fileTable}>
                <table style={styles.table}>
                    <thead>
                    <tr>
                        <th style={styles.th}>id</th>
                        <th style={styles.th}>파일명</th>
                        <th style={styles.th}>파일 크기</th>
                        <th style={styles.th}>확장자</th>
                        <th style={styles.th}>업로드 날짜</th>
                        <th style={styles.th}></th>
                    </tr>
                    </thead>
                    <tbody>
                    {fileList.map((file, index) => (
                        <tr key={index} style={index % 2 === 1 ? styles.evenRow : {}}>
                            <td style={styles.td}>{index + 1}</td>
                            <td style={styles.td}>{file.originalFileName}</td>
                            <td style={styles.td}>{formatFileSize(file.fileSize)}</td>
                            <td style={styles.td}>{file.fileType.split("/")[1]}</td>
                            <td style={styles.td}>{file.createdDate}</td>
                            <td style={styles.td}>
                                <button style={styles.button} onClick={() => deleteFile(file.fileName)}>삭제</button>
                                <button style={styles.button} onClick={() => downloadFile(file.fileName)}>다운로드</button>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default FileList;
