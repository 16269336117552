import React, { useState, useRef } from 'react';
import { useDispatch } from "react-redux";
import { isActiveModal } from "../../../../modules/actions/modalAction";
import axios from 'axios';

const API_URL = 'http://192.168.219.74:8080/api/file/upload';
const MAX_FILE_SIZE = 500 * 1024 * 1024;

const FileUploadModal = () => {
    const dispatch = useDispatch();
    const [selectedFile, setSelectedFile] = useState(null);
    const [isDragging, setIsDragging] = useState(false);
    const fileInputRef = useRef(null);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file && file.size <= MAX_FILE_SIZE) {
            setSelectedFile(file);
        } else {
            alert('파일 크기가 500MB를 초과합니다. 다른 파일을 선택해주세요.');
        }
    };

    const resetFileSelection = () => {
        setSelectedFile(null);
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };

    const handleDragEnter = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(true);
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);

        const file = e.dataTransfer.files[0];
        if (file && file.size <= MAX_FILE_SIZE) {
            setSelectedFile(file);
        } else {
            alert('파일 크기가 500MB를 초과합니다. 다른 파일을 선택해주세요.');
        }
    };

    const uploadFile = async () => {
        if (!selectedFile) {
            alert('파일을 선택해주세요.');
            return;
        }

        const formData = new FormData();
        formData.append('multipartFiles', selectedFile);

        try {
            console.log('Uploading file:', selectedFile.name);
            const response = await axios.post(API_URL, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'accept': '*/*'
                }
            });
            console.log('File uploaded successfully', response.data);
            alert('파일이 성공적으로 업로드되었습니다.');
            resetFileSelection();
            dispatch(isActiveModal());
        } catch (error) {
            console.error('Error uploading file:', error);
            resetFileSelection();
            alert(`파일 업로드 중 오류가 발생했습니다: ${error.message}`);
        }
        resetFileSelection();
    };

    return (
        <div className="modal-wrapper">
            <div className="modal-content">
                <h2>파일 업로드</h2>
                <span>최대 사이즈: 500MB</span>
                <div
                    className={`drop-zone ${isDragging ? 'dragging' : ''}`}
                    onDragEnter={handleDragEnter}
                    onDragLeave={handleDragLeave}
                    onDragOver={handleDragOver}
                    onDrop={handleDrop}
                >
                    {selectedFile ? (
                        <p>{selectedFile.name}</p>
                    ) : (
                        <p>파일을 여기에 드래그하거나 클릭하여 선택하세요</p>
                    )}
                    <input
                        type="file"
                        ref={fileInputRef}
                        onChange={handleFileChange}
                        style={{ display: 'none' }}
                    />
                </div>
                <button onClick={() => fileInputRef.current.click()}>파일 선택</button>
                <div className="button-group">
                    <button onClick={uploadFile}>업로드</button>
                    <button onClick={() => {
                        resetFileSelection();
                        dispatch(isActiveModal());
                    }}>취소
                    </button>
                </div>
            </div>
        </div>
    );
};

// 스타일 추가
const styles = `
  body {
    margin: 0;
    padding: 0;
    overflow: hidden;
  }

  .modal-wrapper {
    position: fixed;
    top: 0;
    left: 50px;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }

  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    width: 100%;
    max-width: 300px;
    max-height: 80vh;
    overflow-y: auto;
  }

  .drop-zone {
    border: 2px dashed #ccc;
    border-radius: 5px;
    padding: 20px;
    text-align: center;
    margin: 20px 0;
    cursor: pointer;
  }

  .drop-zone.dragging {
    background-color: #f0f0f0;
    border-color: #999;
  }

  .button-group {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }

  button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background-color: #f0f0f0;
  }

  button:hover {
    background-color: #e0e0e0;
  }
`;


// 스타일을 head에 추가
const styleSheet = document.createElement("style");
styleSheet.type = "text/css";
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);

export default FileUploadModal;
