import React from 'react';
import {Route, Routes} from "react-router-dom";
import {UserList} from "../../pages";
import {Chat} from "../../pages";
import {FileList} from "../../pages";

const Middle = () => {
    return (
        <Routes>
            <Route path="/" element={<UserList/>}/>
            <Route path="/file" element={<FileList/>}/>
            <Route path="/chat" element={<Chat/>}/>
        </Routes>
    )
}

export default Middle