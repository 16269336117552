import React from 'react';
import './_style.scss';
import {useDispatch, useSelector} from "react-redux";
import {setUserInfo} from "../../../modules/actions/setUserInfo";
import {isAuthenticated} from "../../../modules/actions/isAuthenticated";

const Header = () => {
    const userInfo = useSelector(status => status.userInfo);
    const dispatch = useDispatch();

    const logout = () => {
        dispatch(setUserInfo({}));
        dispatch(isAuthenticated());
    }

    return (
        <div className="Header" style={{display: userInfo.name !== undefined ? "" : "none"}}>
            <img className="logo_img" src='/images/logo-inverse.png' alt=""/>
            <div className="login_info">
                <div className="user_img">
                    < i className="ri-user-line"></i>
                </div>
                <div className="">
                    <div className="userName">
                        {userInfo.name ? userInfo.name : "undefined"}
                    </div>
                    <div className="notice">
                        환영합니다.
                    </div>
                </div>
                <div className="logout_btn" onClick={() => logout()}>
                    <i className="ri-logout-box-r-line"></i>
                </div>
            </div>
        </div>
    )
}

export default Header;