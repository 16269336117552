import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const ProtectedRoute = ({ children }) => {
    const navigate = useNavigate();
    const isAuthenticated = useSelector((state) => state.isAuthenticated);

    useEffect(() => {
        if (!isAuthenticated) {
            navigate('/login'); // 로그인되지 않은 경우 로그인 페이지로 리다이렉트
        }
    }, [isAuthenticated, navigate]);

    return isAuthenticated ? children : null;
};

export default ProtectedRoute;