import React, {useCallback, useState} from 'react';
import Input from "../../atoms/Input";
import {useDispatch, useSelector} from "react-redux";
import {setUserInfo} from "../../../modules/actions/setUserInfo";
import {useNavigate} from 'react-router-dom';
import {isAuthenticated} from "../../../modules/actions/isAuthenticated";
import axios from "axios";

const Login = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [id, setId] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const handleLogin = useCallback(async (e) => {
        e.preventDefault();
        setError('');

        const data = {
            loginId: id,
            password: password
        };

        try {
            const res = await axios.post('http://192.168.219.74:8080/api/auth/login', data, {
                headers: {
                    'accept': '*/*',
                    'Content-Type': 'application/json'
                }
            });

            if (res && res.status === 200) {
                const { data } = res;

                const userInfo = {
                    name: "admin",
                    id: id,
                    uuid: data.accessToken
                };

                // setUserInfo에 전달되는 값을 로그로 출력
                console.log('setUserInfo에 전달되는 값:', userInfo);

                dispatch(setUserInfo(userInfo));
                dispatch(isAuthenticated());

                navigate("/");
            }
        } catch (error) {
            console.error('Login error:', error);
            setError('로그인에 실패했습니다. 아이디와 비밀번호를 확인해주세요.');
        }
    }, [id, password, dispatch, navigate]);


    const onSubmit = () => {
        dispatch(setUserInfo({name: "finn.seok", id: "", uuid: ""}))
        dispatch(isAuthenticated())
        navigate("/")
    }
    
    return (
        <div className="login">
            <img src="/images/logo-inverse.png" alt=""/>
            <div className="login_div">
                로그인
                <div className="inputBox">
                    <Input label={"아이디"} type={"text"} required={true} value={id}
                           onChange={(e) => setId(e.target.value)}/>
                    <Input label={"패스워드"} type={"password"} required={true} value={password}
                           onChange={(e) => setPassword(e.target.value)}/>
                </div>
                {error && <p className="error-message">{error}</p>}
                <div onClick={(e) => handleLogin(e)} className="loginSubmit">
                    로그인(Api)
                </div>

                <div onClick={() => onSubmit()} className="loginSubmit">
                    로그인(임시)
                </div>
            </div>
        </div>
    )
}

export default Login;