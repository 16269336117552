import React from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';

import {Login} from "../components/pages"
import ProtectedRoute from "./ProtectedRoute";
import Middle from "../components/organisms/Middle";
import Modal from "../components/atoms/Modal";
import SideMenu from "../components/organisms/SideMenu";

const AppRouter = () => {
    return (
        <Routes>
            <Route path="/login" element={<Login/>}/>
            <Route path="/*" element={
                <ProtectedRoute>
                    <SideMenu/>
                    <Middle/>
                    <Modal/>
                </ProtectedRoute>
            }/>
        </Routes>
    );
};

export default AppRouter;