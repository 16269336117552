import React from 'react';
import './_style.scss';
import {useDispatch, useSelector} from "react-redux";
import {isActiveSideMenu} from "../../../modules/actions/isActiveSideMenu";
import {useNavigate} from "react-router-dom";
import {isActiveModal} from "../../../modules/actions/modalAction";
import axios from "axios";

const SideMenu = () => {
    const navigate = useNavigate();
    const activeMenu = useSelector(status => status.isActiveSideMenu);
    const dispatch = useDispatch();

    const testPage = (link) => {
        navigate(link)
    }

    return (
        <div className="sideMenu" style={{
            animation: activeMenu ? "fadeInRight 1s" : "fadeOutLeft 1s",
            marginLeft: activeMenu ? "0px" : "-150px"
        }}>
            <div className="nav" onClick={() => testPage("/")}>
                <i className="ri-home-line"></i>
                <label>Home</label>
            </div>

            <div className="nav" onClick={() => testPage("/file")}>
                <i className="ri-file-line"></i>
                <label>file</label>
            </div>

            <div className="nav" onClick={() => testPage("/chat")}>
                <i className="ri-flask-line"></i>
                <label>Chat</label>
            </div>

            <div className="sideMenu_expand" onClick={() => dispatch(isActiveSideMenu())}>
                {
                    activeMenu ?
                        <i className="ri-menu-unfold-2-line"></i>
                        :
                        <i className="ri-menu-unfold-line"></i>
                }
            </div>
        </div>
    )
}

export default SideMenu;